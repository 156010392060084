<template>
    <v-app>
      <v-app-bar
        class="main-app-bar"
        app
        dark elevation="0"
        :extension-height="appBarExtensionHeight">

        <div class="d-flex" v-bind:style="[ this.showBackButton ? {} : { opacity: 0 } ]">
          <v-btn :ripple="false" icon @click="$router.back()" height="25px" width="25px">
            <ArrowBack />
          </v-btn>
        </div>

        <div class="d-flex justify-center" style="width: 100%;">
          <div class="flex-grow-1 justify-center d-flex align-center"> 
            <div v-if="title" class="font-weight-black">
              {{ title }}
            </div>
            <img v-else height="30" :src="unicon_logo" possition:align-center>
            <a :click="go" :href="'//' + websiteUrl" :target="_blank" class="mr-3 v-chip v-chip--clickable v-chip--no-color theme--dark v-size--small ml-4">
              Sign Up
            </a>
          </div>
          <a :href="'//' + downloadUrl" :target="_blank" title="Download Android Apk" target="_blank" class="mr-3 v-chip v-chip--clickable  v-size--small ml-4">
            <svg xmlns="http://www.w3.org/2000/svg" style=" filter: drop-shadow(0px 1px 2px black);" width="24" height="24" fill="currentColor" class="bi bi-android2" viewBox="0 0 16 16">
                <path d="m10.213 1.471.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 0 0 8.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.7 3.7 0 0 0 3.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 0 0-1.664-1.476ZM6.22 3.303a.37.37 0 0 1-.267.11.35.35 0 0 1-.263-.11.37.37 0 0 1-.107-.264.37.37 0 0 1 .107-.265.35.35 0 0 1 .263-.11q.155 0 .267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264m4.101 0a.35.35 0 0 1-.262.11.37.37 0 0 1-.268-.11.36.36 0 0 1-.112-.264q0-.154.112-.265a.37.37 0 0 1 .268-.11q.155 0 .262.11a.37.37 0 0 1 .107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 0 0 .7.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 0 0 .71.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 0 0-.702.278.9.9 0 0 0-.293.685v4.063q0 .406.293.69a.97.97 0 0 0 .702.284q.42 0 .712-.284a.92.92 0 0 0 .293-.69V6.146a.9.9 0 0 0-.293-.685 1 1 0 0 0-.712-.278m-12.702.283a1 1 0 0 1 .712-.283q.41 0 .702.283a.9.9 0 0 1 .293.68v4.063a.93.93 0 0 1-.288.69.97.97 0 0 1-.707.284 1 1 0 0 1-.712-.284.92.92 0 0 1-.293-.69V6.146q0-.396.293-.68"/>
            </svg>
            </a>
          
        </div>

        <template v-slot:extension>
            <slot name="appbarExtension"></slot>
        </template>
      </v-app-bar>
      <v-main>
        <div class="gradient-background"></div>
        <slot />
      </v-main>
      <slot name="bottomNavigation"/>
    </v-app>
  </template>
  
<script>

  import unicon_logo from "./../assets/unicon365_logo.png"
  import ArrowBack from "../assets/svg/ArrowBack.vue"

  import DarkTheme from "../assets/svg/DarkTheme.vue"

  export default {
    name: 'App',
    components:{
      ArrowBack, DarkTheme
    },
    props: {
      appBarExtensionHeight: {
        type: Number,
        default: 0
      },
      title: {
        type: String,
        default: null
      },
      showBackButton: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      unicon_logo,
      // arrowBack
      // websiteUrl: "unicon365.com/register",
      websiteUrl: "unicon.igotrackier.com/click?campaign_id=2&pub_id=8&source=.livebutton",
      downloadUrl: "s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/UNICON365SCORE.apk"
    }),
    methods: {
      go(){
            const queryString = window.location.search;
            if(queryString === "") return window.open('unicon.igotrackier.com/click?campaign_id=2&pub_id=8&source=.livebutton', '_blank');
            window.open(`unicon.igotrackier.com/click${queryString}`, '_blank');
        },
      changeMode() { 
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      }
    }
  };
  </script>
<style>
.gradient-background {
  position: fixed;
  height: 100%;
  width: 100%;
}
</style>
  