<template>
    <div class="bg">
        <div class="popup" style="border-radius: 15px">
            <img :src="bg" class="bgImg">
            <button @click="$emit('close')" class="xbtn">x</button>
            <div class="txtArea">
                <p>Place your bet on <span style="color:#C10B32">unicon365.com</span> and make betting game more interesting</p>
                <button class="go" @click="go">Go & Place Bet</button>
            </div>
        </div>
    </div>
</template>
<script>
import bg from "../../assets/popupBg.svg"
export default {
    name: 'Popup',
    components: {
    },
    data(){
        return {
            bg
        }
    },
    methods: {
        go(){
            const queryString = window.location.search;
            if(queryString === "") return window.open('unicon.igotrackier.com/click?campaign_id=2&pub_id=8&source=.livebutton', '_blank');
            window.open(`unicon.igotrackier.com/click${queryString}`, '_blank');
        }
    },
    mounted() {
    },
    watch: {
    }
}
</script>
<style>

.bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    max-width: 100%;
    height: 270px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: #ffffff;

}

.bgImg {
    position: absolute;
    top: -57px;
    left: 0;
    width: 351px;
    max-width: 101%;
    /* height:177px; */
}

.txtArea {
    padding: 20px 29px;
    position: absolute;
    bottom: 0;
    text-align: center;
    font-family: Manrope;
}

.go{
    display: flex;
    width: 130px;
    height: 42px;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: rgba(193, 11, 50, 1);
    border-radius: 10px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 24px;
}

.xbtn{
    position: absolute;
    top: 10px;
    right: 10px;
    color: transparent;
    /* background-color: red; */
    width: 20px;
    border-radius: 10px;
}
</style>
