<template>

    <MainLayout :title="title" :showBackButton="true">

        <div class="content">
            
            <div class="pt-4 bg-type-1" style="border-bottom: 1px solid var(--grey);">

                <div class="mx-4 d-flex align-center font-weight-bold" style="font-size: 14px;">
                    <div v-if="match_status == 'LIVE'">
                        <span class="live-beacon"></span>
                        <span class="ml-2">Live</span>
                    </div>

                    <div v-else-if="match_status == 'UPCOMING'" class="text-type-4">
                        Upcoming
                    </div>

                    <div v-else-if="match_status == 'PLAYED'" class="text-type-3">
                        Result
                    </div>

                    <span v-if="live_line && live_line.current_inning" class="ml-1" style="color: #9FA9AA;">
                        / Inn: {{ live_line.current_inning }}
                    </span>
                </div>

                <div v-if="live_line && Object.keys(live_line).length > 0">
                    <div class="px-4 pt-4">
                        
                        <div v-if="live_line && live_line.result !== '' ">
                            <v-row no-gutters class="mb-2">
                                <v-col cols="6">
                                    <div class="d-flex align-center">
                                        <div>
                                            <v-img v-if="live_line.team_a_img" class="mr-2 rounded-circle img-bg" height="20px" width="20px" :src="live_line.team_a_img"></v-img>
                                        </div>
                                        <div class="font-weight-black" style="line-height: 1;">{{ live_line.team_a_short }}</div>
                                    </div>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-black">{{ live_line.team_a_scores }}</span> <span class="text-type-2">({{ live_line.team_a_over }})</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <div class="d-flex align-center">
                                        <div>
                                            <v-img v-if="live_line.team_b_img" class="mr-2 rounded-circle img-bg" height="20px" width="20px" :src="live_line.team_b_img"></v-img>
                                        </div>
                                        <div class="font-weight-black" style="line-height: 1;">{{ live_line.team_b_short }}</div>
                                    </div>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-black">{{ live_line.team_b_scores }}</span> <span class="text-type-2">({{ live_line.team_b_over }})</span>
                                </v-col>
                            </v-row>
                        </div>
                        
                        <div v-else-if="live_line && live_line.toss == ''" class="d-flex">
                            <div class="flex-grow-1">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img v-if="live_line.team_a_img" class="mr-2 rounded-circle img-bg" height="40px" width="40px" :src="live_line.team_a_img"></v-img>
                                    </div>
                                    
                                    <div>
                                        <div class="font-weight-black mb-1" style="line-height: 1;">{{ live_line.team_a }}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Spark/>
                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex align-center justify-end">
                                    <div>
                                        <div class="font-weight-black mb-1 text-right" style="line-height: 1;">{{ live_line.team_b }}</div>
                                    </div>
                                    <div>
                                        <v-img v-if="live_line.team_b_img" 
                                            class="ml-2 rounded-circle img-bg"
                                            height="40px" width="40px" 
                                            :src="live_line.team_b_img"></v-img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div v-else-if="live_line && live_line.toss != ''" class="d-flex">
                            <div style="width: 50%;">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img v-if="current_team.img" 
                                            class="mr-2 rounded-circle img-bg"
                                            height="40px"
                                            width="40px" 
                                            :src="current_team.img"/>
                                    </div>
                                    <div>
                                        <div class="d-flex align-center mb-1">
                                            <div class="font-weight-black" style="line-height: 1;">{{ current_team.short }}</div>
                                        </div>
                                        <div style="line-height: 1;">
                                            <span style="font-size: 20px;" class="font-weight-black">{{ current_team.scores }}</span> <span v-if="current_team.over" style="color: #9FA9AA;">({{ current_team.over }})</span>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin: 12px 0px 0px 6px; font-size: 14px;">
                                    <span v-if="live_line.curr_rate" class="mr-2">
                                        <span style="color:rgb(159, 169, 170);">CRR:</span> {{ live_line.curr_rate }}
                                    </span>
                                    <span v-if="live_line.rr_rate">
                                        <span style="color:rgb(159, 169, 170);">RRR:</span> {{ live_line.rr_rate }}
                                    </span>
                                </div>
                            </div>
                            <div style="width: 50%;">
                                <div
                                    class="text-type-4 font-weight-black text-center"
                                    style="height: 100%; display: flex; align-items: center; justify-content: center;"
                                    :style="{ fontSize: live_line.first_circle.length > 16 ? '18px' : '26px' }">
                                    {{ live_line.first_circle }}
                                </div>
                            </div>
                        </div>


                        
                    </div>
                    <div class="d-flex align-center ps-5 mt-5 mb-2" style="border-top: 1px solid #ccdfe1; border-bottom: 1px solid #ccdfe1; padding: 4px 0px;">
                        <span v-if="this.current_status">{{ this.current_status }}</span>
                        <span v-else-if="match_info && Object.keys(match_info).length > 0">
                            <Clock class="mr-2" height="16px" width="16px"/>
                            Match Starts on {{ match_info.match_date.replace("-", " ") }}, {{ match_info.match_time }}
                        </span>
                    </div>
                </div>
                <v-tabs class="match-info-tabs" v-model="tab" background-color="transparent">
                    <!-- <v-tab v-for="(item, i) in tabs" :key="i">{{ item }}</v-tab> -->
                    <v-tab href="#info">Info</v-tab>
                    <v-tab v-if="show_live_tabs" href="#live">Live</v-tab>
                    <v-tab v-if="show_live_tabs" href="#commentary">Commentary</v-tab>
                    <v-tab v-if="show_live_tabs" href="#scorecard">Scorecard</v-tab>
                    <!-- <v-tab href="#news">News</v-tab> -->
                    <v-tab href="#squads">Squads</v-tab>
                    <!-- <v-tab>Standings</v-tab> -->
                </v-tabs>
            </div>

            <v-tabs-items v-model="tab"> 
                <v-tab-item value="info">
                    <Info 
                        :toss="live_line?.toss" 
                        :data="match_info"
                        :loading="loading_match_info"/>
                </v-tab-item>
                <v-tab-item v-if="show_live_tabs" value="live">
                    <Live :data="live_line"
                        :loading="loading_live_line"/>
                </v-tab-item>
                <v-tab-item v-if="show_live_tabs" value="commentary">
                    <Commentary/>
                </v-tab-item>
                <v-tab-item v-if="show_live_tabs" value="scorecard">
                    <Scorecard/>
                </v-tab-item>
                <!-- <v-tab-item value="news">
                    <News/>
                </v-tab-item> -->
                <v-tab-item value="squads">
                    <Squads/>
                </v-tab-item>
                <!-- <v-tab-item>
                    <Standings/>
                </v-tab-item> -->
            </v-tabs-items>
        </div>
    </MainLayout>
</template>
<script>

import MainLayout from "./../../layouts/MainLayout";

import Spark from "../../assets/svg/Spark.vue";
import Trophy from "../../assets/svg/Trophy.vue";
import Coin from "../../assets/svg/Coin.vue";

import Clock from "../../assets/svg/Clock.vue";

import team5 from "../../assets/team-5.png";
import team6 from "../../assets/team-6.png";

import Info from "./../../components/MatchInfo/Info.vue"
import Live from "./../../components/MatchInfo/Live.vue"
import Commentary from "./../../components/MatchInfo/Commentary.vue"
import Scorecard from "./../../components/MatchInfo/Scorecard.vue"
import Squads from "./../../components/MatchInfo/Squads.vue"
import Standings from "./../../components/MatchInfo/Standings.vue"
import News from "./../../components/MatchInfo/News.vue"

export default {
    name: 'MatchInfo',
    components: {
        MainLayout,
        Spark, Trophy, Coin, Clock,
        Info, Live, Commentary, Scorecard, Squads, Standings, News
    },
    data() {
        return {
            match_id: this.$route.params.match_id,
            team5, team6,
            tab: null,
            tabs: [
                'info', 'commentary', 'scorecard', 'news', 'squads', 'standings'
            ],
            data: null,
            match_info: null,
            loading_match_info: false,

            live_line: null,
            loading_live_line: false,

            timeout: null,
            show_live_tabs: true,
            
        }
    },
    methods: {
        
        fetchLiveLine() {
            
            if(this.match_info && this.match_info.match_date && this.match_info.match_time) {
                let matchTime = this.$dayjs(this.match_info.match_date + "-2023 " + this.match_info.match_time, "DD-MMM-YYYY hh:mm A");
                if(!this.$dayjs().isAfter(matchTime)) {
                    return;
                }
            }

            this.loading_live_line = true;

            api("liveLine/" + this.match_id)
                .then(response => {
                    console.log("liveLine", response.data)
                    this.loading_live_line = false;
                    if(response && response.status) {
                        this.live_line = response.data;
                    }else{
                        this.live_line = [];
                    }
                    this.fetchLiveLineAgain();
                }).catch(error => {
                    this.fetchLiveLineAgain();
                    this.loading_live_line = false;
                    this.live_line = [];
                    console.log("error", error);    
                })

        },
        fetchLiveLineAgain() {
            if(this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.fetchLiveLine()
            }, 250);
        },
        fetchMatchInfo() {

            this.loading_match_info = true;

            api("matchInfo/" + this.match_id)
                .then(response => {
                    console.log("matchInfo", response)
                    this.loading_match_info = false;
                    if(response && response.status) {
                        this.match_info = response.data;
                    }else{
                        this.match_info = [];
                    }
                }).catch(error => {
                    this.loading_match_info = false;
                    this.match_info = [];
                    console.log("error", error)
                })
        },
        showLandingPage() {

            if(this.$route.query.match_status) {
                switch(this.$route.query.match_status) {
                    case 'upcoming':
                        this.tab = "info";
                        break;
                    case 'live':
                        this.tab = "live";
                        break;
                    case 'recent':
                        this.tab = "scorecard";
                        break;
                }
            }
        }
    },
    mounted() {
        this.fetchLiveLine();
        this.fetchMatchInfo();

        this.showLandingPage();
        

        // setTimeout(() => {
        //     this.tab = 4;
        // }, 4000);

    },
    computed: {
        title() {
            return this.live_line ? `${this.live_line.team_a_short} vs ${this.live_line.team_b_short}` : 'Match Info';
        },
        current_team() {
            if(this.live_line) {
                if(this.live_line.batting_team == this.live_line.team_a_id) {
                    return {
                        img: this.live_line.team_a_img,
                        short: this.live_line.team_a_short,
                        scores: this.live_line.team_a_scores,
                        over: this.live_line.team_a_over
                    };
                } else {
                    return {
                        img: this.live_line.team_b_img,
                        short: this.live_line.team_b_short,
                        scores: this.live_line.team_b_scores,
                        over: this.live_line.team_b_over
                    };
                }
            } else {
                return null;
            }
        },
        current_status() {
            if(this.live_line && this.live_line.result) {
                return this.live_line.result;
            }
            else if(
                this.live_line && 
                this.live_line.ball_rem && 
                this.live_line.run_need) {
                return `Need ${this.live_line.run_need} from ${this.live_line.ball_rem} balls`
            }else if(this.live_line && this.live_line.toss && this.live_line.toss != "") {
                return this.live_line.toss;
            }else {
                return null;
            }
        },
        match_status() {
            if(this.live_line) {
                if(this.live_line.result != '') {
                    return "PLAYED";
                }
                else if(this.live_line.toss != '') {
                    return "LIVE";
                }
                else if(this.live_line.toss == '') {
                    return "UPCOMING";
                }
            }
            return null;
        }
    },
    watch:{
        'live_line.toss': {
            handler() {
                if(this.live_line.toss !== "") {
                    this.show_live_tabs = true;
                }else{
                    this.show_live_tabs = false;
                }
            }
        }
    },
    beforeDestroy() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}
</script>
<style lang="scss">
.match-info-tabs {
    .v-tabs-bar .v-tab:not(.v-tab--active) 
    {
        color: #ffffff !important;
        
    }
}
.live-beacon {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    position: relative;
    &::before {
        content: "";
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: red;
        animation-name: beacon;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
}
@keyframes beacon {
    0% {
        height: 100%;
        width: 100%;
        opacity: 1;
    }
    100%{
        height: 300%;
        width: 300%;
        opacity: 0;
    }
}
</style>